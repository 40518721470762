define("ember-cli-notifications/components/ecn-icon-close", ["exports", "@ember/component", "ember-cli-notifications/templates/components/ecn-icon-close"], function (_exports, _component, _ecnIconClose) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } /* eslint-disable ember/no-classic-components */
  class EmberCliNotificationsIconCloseComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "layout", _ecnIconClose.default);
      _defineProperty(this, "tagName", '');
    }
  }
  _exports.default = EmberCliNotificationsIconCloseComponent;
});